import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont';
import '@mdi/font/css/materialdesignicons.css';


Vue.use(Vuetify);



Vue.component('my-component', {
  methods: {
    changeLocale(){
      this.$vuetify.lang.current = 'es'
    }
  }
})

export default new Vuetify({
  icons:{
    iconfont: 'mdi' || 'fa' || 'md',
  },
 
});
