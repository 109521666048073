<template>
	<v-app class="grey lighten-4">
		<v-main>
			<!-- <transition name="slide-fade"> -->
			<router-view />
			<!-- </transition> -->
		</v-main>
	</v-app>
</template>

<script>
	export default {
		name: 'App',
		components: {},
		data() {
			return {};
		},
		mounted() {
			document.title = 'Aurora';
		},
	};
</script>

<style>
	.slide-fade-enter-active {
		transition: all 0.3s ease;
	}
	.slide-fade-leave-active {
		transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
	}
	.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateX(10px);
		opacity: 0;
	}
	a {
		text-decoration: none;
	}
</style>
