import Vue from 'vue';
import VueRouter from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('../views/dashboard'),
	},
	{
		path: '/panelven',
		name: 'panelven',
		component: () => import('../views/panelven'),
	},
	{
		path: '/panelped',
		name: 'panelped',
		component: () => import('../views/panelped'),
	},
	{
		path: '*',
		name: 'inicio',
		component: () => import('../views/inicio'),
	},

	{
		path: '/clientes',
		name: 'clientes',
		component: () => import('../views/clientes'),
	},
	{
		path: '/saldos',
		name: 'saldos',
		component: () => import('../views/saldos'),
	},

	{
		path: '/cobros',
		name: 'cobros',
		component: () => import('../views/cobros'),
	},

	{
		path: '/pagos',
		name: 'pagos',
		component: () => import('../views/pagos'),
	},

	{
		path: '/minimos',
		name: 'minimos',
		component: () => import('../views/minimos'),
	},

	{
		path: '/invconsumo',
		name: 'invconsumo',
		component: () => import('../views/invconsumo'),
	},
	{
		path: '/intransito',
		name: 'intransito',
		component: () => import('../views/intransito'),
	},
	{
		path: '/invcompras',
		name: 'invcompras',
		component: () => import('../views/invcompras'),
	},
	{
		path: '/venmapa',
		name: 'venmapa',
		component: () => import('../views/venmapa'),
	},
	{
		path: '/climapa',
		name: 'climapa',
		component: () => import('../views/climapa'),
	},
	{
		path: '/venarticulos',
		name: 'venarticulos',
		component: () => import('../views/venarticulos'),
	},
	{
		path: '/venclientes',
		name: 'venclientes',
		component: () => import('../views/venclientes'),
	},
	{
		path: '/prevenagentes',
		name: 'prevenagentes',
		component: () => import('../views/prevenagentes'),
	},
	{
		path: '/ventagentes',
		name: 'ventagentes',
		component: () => import('../views/ventagentes'),
	},
	/* {
		path: '/venagentes',
		name: 'venagentes',
		component: () => import('../views/venagentes'),
	}, */
	{
		path: '/grafica',
		name: 'grafica',
		component: () => import('../views/grafica'),
	},
	{
		path: '/detapreventa',
		name: 'detapreventa',
		component: () => import('../views/detapreventa'),
	},
	{
		path: '/detacobros',
		name: 'detacobros',
		component: () => import('../views/detacobros'),
	},
	{
		path: '/detapagos',
		name: 'detapagos',
		component: () => import('../views/detapagos'),
	},
	{
		path: '/backorder',
		name: 'backorder',
		component: () => import('../views/backorder'),
	},
	,
];
const router = new VueRouter({
	// history: createWebHistory(process.env.BASE_URL),
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
